import React, { useRef, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import { Buffer } from "buffer";
import axios from "axios";
import "./style.css";
import Sidebar from "./components/Sidebar";
import Hyperbeam from "@hyperbeam/web";
import { Modal } from 'antd';
import DisclaimerModal from './components/DisclaimerModal';
import ParticipantList from "./components/ParticipantList";
import ApplauseOverlay from './components/ApplauseOverlay'; 
import SideWindow from "./components/SideWindow";
import {
  LoadingOutlined
} from '@ant-design/icons';

const Room = () => {
  const hideUrlParams = () => {
    let url = new URL(window.location.href);
    let paramNames = ['meetingToken', 'authHeader', 'meeting_name', 'user_name'];
    paramNames.forEach(param => {
      url.searchParams.delete(param);
    });
    history.pushState({}, '', url);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const meetingId = searchParams.get("meeting_id");
  const meetingToken = searchParams.get("meetingToken") || localStorage.getItem(`meetingToken_${meetingId}`);
  const authHeader = searchParams.get("authHeader") || localStorage.getItem(`authHeader_${meetingId}`);
  const meetingName = searchParams.get("meeting_name") || localStorage.getItem(`meetingName_${meetingId}`);
  const user_name = searchParams.get("user_name") || localStorage.getItem(`user_name_${meetingId}`);
  const recording_consent = localStorage.getItem(`recording_consent_${meetingId}`) || "false";
  const savedRecordingConsent = localStorage.getItem(`recording_consent_${meetingId}`) == "true" ? true : false;
  localStorage.setItem(`meetingToken_${meetingId}`, meetingToken);
  localStorage.setItem(`authHeader_${meetingId}`, authHeader);
  localStorage.setItem(`meetingName_${meetingId}`, meetingName);
  localStorage.setItem(`user_name_${meetingId}`, user_name);
  localStorage.setItem(`recording_consent_${meetingId}`, recording_consent);

  const [isLoading, setIsLoading] = useState(true);
  const [isValidMeet, setIsValidMeet] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [jwtToken, setJwtToken] = useState(null);
  const [userName, setUserName] = useState(user_name || "No-name-user");
  const [email, setEmail] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [recordingConsent, setRecordingConsent] = useState(savedRecordingConsent);
  const [showRecordingConsent, setShowRecordingConsent] = useState(false);
  //const [filmStripWidth, setFilmStripWidth] = useState(150);
  const [isSideWindowOpen, setSideWindowOpen] = useState(false);


  const accessToken = meetingToken || null;
  const accessHeader = authHeader && (authHeader != "null")? Buffer.from(authHeader, "base64").toString("utf-8") : null;

  const apiRef = useRef();
  const [logItems, updateLog] = useState([]);
  const [showNew, toggleShowNew] = useState(false);
  const [knockingParticipants, updateKnockingParticipants] = useState([]);
  const [isHangupModalVisible, setIsHangupModalVisible] = useState(false);
  const [leaveMeetingAction, setLeaveMeetingAction] = useState("hangup");
  const [isVideoContentShared, setIsVideoContentShared] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCollaborate, setIsCollaborate] = useState(false);
  const [hbComputer, setHbComputer] = useState(null);
  const [hbEmbed, setHbEmbed] = useState(null);
  const [hbSessionLoading, setHbSessionLoading] = useState(false);
  const [moderatorFromApi, setModeratorFromApi] = useState(false);
  const [localParticipantId, setLocalParticipantId] = useState(null);
  const [meetingType, setMeetingType] = useState(null);
  const [isWebinar, setIsWebinar] = useState(false);
  const [showWaitForModerator, setShowWaitForModerator] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [enableLobby, setEnableLobby] = useState(false);
  const [isParticipantListOpen, setIsParticipantListOpen] = useState(false);
  const [localSelfviewDisabled, setLocalSelfviewDisabled] = useState(false);
  const [localMicMuted, setLocalMicMuted] = useState(false);
  const [localApplaudType, setLocalApplaudType] = useState(null);
  const [showApplause, setShowApplause] = useState(false);

  /* const handleParticipantJoined = (id, displayName) => {
    participants.push({ id, displayName });
    setParticipants(participants); console.log("==================================", participants);
  };

  const handleParticipantLeft = (id) => {
    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p.id !== id)
    );
  }; */

  const toggleSideWindow = () => {
    setSideWindowOpen(!isSideWindowOpen);
    apiRef.current.executeCommand('toggleParticipantsPane', !isSideWindowOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };  

  useEffect(() => {
    hideUrlParams();
    isValidToken()
      .then((response) => {
        const data = response.data;
        if(data.isValidAccess === true && data.meetingStatus?.toLowerCase() !== "completed" && data.meetingStatus?.toLowerCase() !== "cancelled"){
          if(data.userName && data.userName.length > 0 && data.userRole === "moderator") {
            setUserName(response.data.userName);
          }
          if(data.email){
            setEmail(data.email);
          }
          if(data.avatarUrl){
            setAvatarUrl(data.avatarUrl);
          }
          setMeetingType(data.meetingType);
          setIsWebinar(data.isWebinar);
          //setEnableLobby(true);
          //setEnableLobby(response.data.enableLobby === true ? true : false);

          if(response.data.userRole === "moderator"){
            if(response.data.jitsiToken){
              setJwtToken(response.data.jitsiToken);
            }
            setIsModerator(true);
            setModeratorFromApi(true);
            setIsLoading(false);
            setIsValidMeet(response.data.isValidAccess);
          }
          else{
            setIsModerator(false);
            setModeratorFromApi(false);
            setIsLoading(false);
            setIsValidMeet(response.data.isValidAccess);
          }
        } else {
          setIsLoading(false);
          setIsValidMeet(false);
        }
         
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsValidMeet(false);
      });

    const updateParticipantList = () => {
      if (apiRef.current) {
        apiRef.current.getRoomsInfo().then(roomsInfo => {
          const mainRoom = roomsInfo.rooms.find(room => room.isMainRoom);
          if (mainRoom) {
            const roomParticipants = mainRoom.participants;
            setParticipants(roomParticipants.map(participant => ({
              id: participant.id,
              displayName: participant.displayName,
              role: participant.role
            })));
          }
        });
        
      }
    };
    // Update the participant list every 5 seconds
    const intervalId = setInterval(updateParticipantList, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if(apiRef.current){
      apiRef.current.executeCommand('overwriteConfig', {disableSelfView: localSelfviewDisabled});
      apiRef.current.isAudioMuted().then(muted => {
        if(localMicMuted !== muted){
          apiRef.current.executeCommand('toggleAudio');
        }
      });
    }
  
    return () => {
      //second
    }
  }, [localSelfviewDisabled, localMicMuted])
  

  const isValidToken = async () => {
    /* if(accessToken === null){
      let response = {};
      response.data = {}
      response.data.isValidAccess = false;
      return response;
    } */
    let data = JSON.stringify({
      "meetingId": meetingId.includes("M-") ? meetingId.slice(2) : meetingId,
      "token" : accessToken,
      "domain": process.env.REACT_APP_JITSIDOMAIN
    })
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/api/v1/schedule/is-valid-access`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios.request(config);
  };

  const updateMeetingStatus = async () => {
    let data = JSON.stringify({
      "meetingId": meetingId.includes("M-") ? meetingId.slice(2) : meetingId,
      "meetingStatus": "Completed",
      "token" : accessToken
    })
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API}/api/v1/schedule/update-meeting-status`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios.request(config);
  };

  let moderatorToolbarButtons = [
    "camera",
    "chat",
    "closedcaptions",
    "desktop",
    "download",
    //"embedmeeting",
    "etherpad",
    "feedback",
    "filmstrip",
    "fullscreen",
    "hangup",
    "help",
    "highlight",
    //"invite",
    "linktosalesforce",
    "livestreaming",
    "microphone",
    "noisesuppression",
    "participants-pane",
    "profile",
    "raisehand",
    "recording",
    "security",
    "select-background",
    "settings",
    "shareaudio",
    //"sharedvideo",
    "shortcuts",
    "stats",
    "tileview",
    "toggle-camera",
    "videoquality",
    //"whiteboard",
  ];
  if(meetingType == 'xl-meet'){
    moderatorToolbarButtons.push("sharedvideo");
  }
  let participantToolbarButtons = [
    "camera",
    "chat",
    "microphone",
    "noisesuppression",
    "raisehand",
    "select-background",
    "tileview",
    "toggle-camera",
    "videoquality",
    "hangup",
  ];
  let startWithAudioMuted = false;
  let disablePolls = false;
  let disabledNotifications = [];
  /* 
  let notifications = []; 
  let disableJoinLeaveNotifications = false;*/
  if(isWebinar){
    /* if(!isModerator){
      disableJoinLeaveNotifications = true;
    } */
    participantToolbarButtons = [
      "chat",
      "raisehand",
      //"noisesuppression",
      "hangup",
      //"microphone",
    ];
    startWithAudioMuted = isModerator ? false : true;
    disablePolls = isModerator ? false : true;

    /* notifications = [
      'connection.CONNFAIL', // shown when the connection fails,
      'dialog.cameraNotSendingData', // shown when there's no feed from user's camera
      'dialog.kickTitle', // shown when user has been kicked
      'dialog.liveStreaming', // livestreaming notifications (pending, on, off, limits)
      'dialog.lockTitle', // shown when setting conference password fails
      'dialog.maxUsersLimitReached', // shown when maximmum users limit has been reached
      'dialog.micNotSendingData', // shown when user's mic is not sending any audio
      'dialog.passwordNotSupportedTitle', // shown when setting conference password fails due to password format
      'dialog.recording', // recording notifications (pending, on, off, limits)
      'dialog.remoteControlTitle', // remote control notifications (allowed, denied, start, stop, error)
      'dialog.reservationError',
      'dialog.serviceUnavailable', // shown when server is not reachable
      'dialog.sessTerminated', // shown when there is a failed conference session
      'dialog.sessionRestarted', // show when a client reload is initiated because of bridge migration
      'dialog.tokenAuthFailed', // show when an invalid jwt is used
      'dialog.transcribing', // transcribing notifications (pending, off)
      'dialOut.statusMessage', // shown when dial out status is updated.
      'liveStreaming.busy', // shown when livestreaming service is busy
      'liveStreaming.failedToStart', // shown when livestreaming fails to start
      'liveStreaming.unavailableTitle', // shown when livestreaming service is not reachable
      'lobby.joinRejectedMessage', // shown when while in a lobby, user's request to join is rejected
      'lobby.notificationTitle', // shown when lobby is toggled and when join requests are allowed / denied
      //'notify.chatMessages', // shown when receiving chat messages while the chat window is closed
      'notify.disconnected', // shown when a participant has left
      'notify.connectedOneMember', // show when a participant joined
      'notify.connectedTwoMembers', // show when two participants joined simultaneously
      'notify.connectedThreePlusMembers', // show when more than 2 participants joined simultaneously
      'notify.leftOneMember', // show when a participant left
      'notify.leftTwoMembers', // show when two participants left simultaneously
      'notify.leftThreePlusMembers', // show when more than 2 participants left simultaneously
      'notify.grantedTo', // shown when moderator rights were granted to a participant
      'notify.hostAskedUnmute', // shown to participant when host asks them to unmute
      'notify.invitedOneMember', // shown when 1 participant has been invited
      'notify.invitedThreePlusMembers', // shown when 3+ participants have been invited
      'notify.invitedTwoMembers', // shown when 2 participants have been invited
      'notify.kickParticipant', // shown when a participant is kicked
      'notify.linkToSalesforce', // shown when joining a meeting with salesforce integration
      'notify.moderationStartedTitle', // shown when AV moderation is activated
      'notify.moderationStoppedTitle', // shown when AV moderation is deactivated
      'notify.moderationInEffectTitle', // shown when user attempts to unmute audio during AV moderation
      'notify.moderationInEffectVideoTitle', // shown when user attempts to enable video during AV moderation
      'notify.moderationInEffectCSTitle', // shown when user attempts to share content during AV moderation
      'notify.mutedRemotelyTitle', // shown when user is muted by a remote party
      'notify.mutedTitle', // shown when user has been muted upon joining,
      'notify.newDeviceAudioTitle', // prompts the user to use a newly detected audio device
      'notify.newDeviceCameraTitle', // prompts the user to use a newly detected camera
      'notify.participantWantsToJoin', // shown when lobby is enabled and participant requests to join meeting
      'notify.passwordRemovedRemotely', // shown when a password has been removed remotely
      'notify.passwordSetRemotely', // shown when a password has been set remotely
      'notify.raisedHand', // shown when a partcipant used raise hand,
      'notify.startSilentTitle', // shown when user joined with no audio
      'notify.unmute', // shown to moderator when user raises hand during AV moderation
      'notify.videoMutedRemotelyTitle', // shown when user's video is muted by a remote party,
      'prejoin.errorDialOut',
      'prejoin.errorDialOutDisconnected',
      'prejoin.errorDialOutFailed',
      'prejoin.errorDialOutStatus',
      'prejoin.errorStatusCode',
      'prejoin.errorValidation',
      'recording.busy', // shown when recording service is busy
      'recording.failedToStart', // shown when recording fails to start
      'recording.unavailableTitle', // shown when recording service is not reachable
      'toolbar.noAudioSignalTitle', // shown when a broken mic is detected
      'toolbar.noisyAudioInputTitle', // shown when noise is detected for the current microphone
      'toolbar.talkWhileMutedPopup', // shown when user tries to speak while muted
      'transcribing.failedToStart', // shown when transcribing fails to start
    ]; */

    disabledNotifications = [
      'lobby.notificationTitle',
      'lobby.joinRejectedMessage', 
      'notify.chatMessages', // shown when receiving chat messages while the chat window is closed
      'notify.disconnected', // shown when a participant has left
      'notify.connectedOneMember', // show when a participant joined
      'notify.connectedTwoMembers', // show when two participants joined simultaneously
      'notify.connectedThreePlusMembers', // show when more than 2 participants joined simultaneously
      'notify.leftOneMember', // show when a participant left
      'notify.leftTwoMembers', // show when two participants left simultaneously
      'notify.leftThreePlusMembers', // show when more than 2 participants left simultaneously
      'notify.grantedTo', // shown when moderator rights were granted to a participant
      'notify.kickParticipant', // shown when a participant is kicked
    ]
  }

  /* const printEventOutput = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
  }; */

  //   const handleModerator = (iD) => {
  //     apiRef.current.executeCommand('grantModerator', iD);
  //   }

  const handleAudioStatusChange = (payload, feature) => {
    if (payload.muted) {
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  /* const handleChatUpdates = (payload) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    apiRef.current.executeCommand("toggleChat");
    updateLog((items) => [
      ...items,
      `you have ${payload.unreadCount} unread messages`,
    ]);
  }; */

  const handleKnockingParticipant = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants) => [
      ...participants,
      payload?.participant,
    ]);
  };

  /* const resolveKnockingParticipants = (condition) => {
    knockingParticipants.forEach((participant) => {
      apiRef.current.executeCommand(
        "answerKnockingParticipant",
        participant?.id,
        condition(participant)
      );
      updateKnockingParticipants((participants) =>
        participants.filter((item) => item.id === participant.id)
      );
    });
  }; */

  const handleJitsiIFrameRef1 = (iframeRef) => {
    // iframeRef.style.border = '10px solid #3d3d3d';
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "100%";
    // iframeRef.style.marginBottom = '20px';
  };

  /* const handleJitsiIFrameRef2 = (iframeRef) => {
    iframeRef.style.marginTop = "10px";
    iframeRef.style.border = "10px dashed #df486f";
    iframeRef.style.padding = "5px";
    iframeRef.style.height = "400px";
  };

  const handleJaaSIFrameRef = (iframeRef) => {
    iframeRef.style.border = "10px solid #3d3d3d";
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "400px";
    iframeRef.style.marginBottom = "20px";
  }; */

  const handleParticipantClick = (id) => {
    apiRef.current.executeCommand('sendEndpointTextMessage', id, 'newMessage');
  };

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
    apiRef.current.on("toolbarButtonClicked", handleToolbarButtonClicked);

    if(avatarUrl){
      apiRef.current.executeCommand('avatarUrl', avatarUrl);
    }

    if(isWebinar && !isModerator){
      apiRef.current.executeCommand('toggleFilmStrip');
      apiRef.current.on("filmstripDisplayChanged", (data) => {
        if(data.visible === true){
          apiRef.current.executeCommand('toggleFilmStrip');
        }
      });

      apiRef.current.on("tileViewChanged", (data) => {
        if(data.enabled === true){
          apiRef.current.executeCommand('toggleTileView');
        }
      });
    }
    if(isWebinar && moderatorFromApi){
      apiRef.current.addEventListener('participantRoleChanged', function (event) {
          if(event.role === 'moderator') {
            // apiRef.current.executeCommand('toggleLobby', true);
            apiRef.current.executeCommand('setLargeVideoParticipant', event.id);
            apiRef.current.executeCommand('setFollowMe', true);
          }
      });
    }

    if(!moderatorFromApi){
      apiRef.current.addEventListener('videoConferenceJoined', function (event) {
        if(!recordingConsent || localStorage.getItem(`recording_consent_${meetingId}`) != "true"){
          setShowRecordingConsent(true);
        }
      });
    }
    
    

    /* apiRef.current.on("participantJoined", (data) => {
      if(moderatorFromApi){
        handleParticipantJoined(data.id, data.displayName);
      }
    });
    apiRef.current.on("participantLeft", (data) => {
      if(moderatorFromApi){
        handleParticipantLeft(data.id);
      }
    });
     */
    apiRef.current.on("endpointTextMessageReceived", (message) => {
      if(message?.data?.eventData?.text == 'hideSelfView'){
        //localStorage.setItem(`overwriteConfig_${meetingId}_disableSelfView`, "true");
        setLocalSelfviewDisabled(true);
      }
      else if(message?.data?.eventData?.text == 'showSelfView'){
        //localStorage.setItem(`overwriteConfig_${meetingId}_disableSelfView`, "false");
        setLocalSelfviewDisabled(false);
      }
      else if(message?.data?.eventData?.text == 'muteUser'){
        setLocalMicMuted(true);
      }
      else if(message?.data?.eventData?.text == 'unmuteUser'){
        setLocalMicMuted(false);
      }
      else if(message?.data?.eventData?.text == 'applaud-clap'){
        setLocalApplaudType("clap");
        setShowApplause(true)
        setTimeout(() => {
          setLocalApplaudType(null);
          setShowApplause(false)
        }, 10*1000);
      }
      

    }); 
    //apiRef.current.executeCommand('displayName', userName);

    


    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    apiRef.current.on("audioMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "audio")
    );
 
    apiRef.current.on("videoMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "video")
    );

    apiRef.current.on("participantRoleChanged", (payload) => {
      setLocalParticipantId(payload.id);

      /* if(payload.role === 'moderator' && moderatorFromApi){
        apiRef.current.executeCommand('toggleLobby', false);
      } */

      if(payload.role === 'moderator'){
        setRoleToolbar('moderator');
      }
      else{
        setRoleToolbar('participant')
      }
    });

    /* apiRef.current.on("faceLandmarkDetected", (payload) => {
      console.log("payload ======================",payload.faceExpression.expression);
    }); */
    /* apiRef.current.on("endpointTextMessageReceived", (message) => {
       if(message?.data?.eventData?.text === "leaveAudio"){
        setSoundOn(false);
        setAllVolume(0);
      }
      if(message?.data?.eventData?.text === "joinAudio"){
        setSoundOn(true);
        setAllVolume(1);
      } 
    });  */
    /* Change Toolbar when local participant role changed to moderator
    apiRef.current.on("participantRoleChanged", (payload) => {
      console.log("role changed", payload);
      if(payload.role === 'moderator') {
        setIsModerator(true)
        apiRef.current.executeCommand('overwriteConfig',
          {
            toolbarButtons: moderatorToolbarButtons
          }
        );
      }
      else{
        if(!moderatorFromApi){
          setIsModerator(false);
          apiRef.current.executeCommand('overwriteConfig',
            {
              toolbarButtons: participantToolbarButtons
            }
          );
        }
      } 
    });*/
    // apiRef.current.on("raiseHandUpdated", printEventOutput);
    // apiRef.current.on("titleViewChanged", printEventOutput);
    // apiRef.current.on("chatUpdated", handleChatUpdates);
    // apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    
  };

  const setRoleToolbar = (role) => {
    const isMod = role==='moderator'?true:false;
    const tools = isMod ? moderatorToolbarButtons : participantToolbarButtons;
    setIsModerator(isMod)
    apiRef.current.executeCommand('overwriteConfig', { toolbarButtons: tools });
  }
  const handleJitsiRoleChange = (payload) => {
    if(moderatorFromApi === false){
      apiRef.current.getRoomsInfo().then((roomsInfo) => {
        const mainRoom = roomsInfo.rooms.find(obj => obj.isMainRoom === true);
        //console.log("=================================main room========================================", mainRoom);
        if(mainRoom.participants?.length <= 1){
          apiRef.current.executeCommand('hangup');
          setShowWaitForModerator(true);
          startWaitingTimer(30);
        }
        else{
          let hasModerator = false;
          let modName = "";
          console.log(mainRoom.participants);
          for (let i = 0; i < mainRoom.participants.length; i++) {
            var user = mainRoom.participants[i];
            if(payload.id != user.id && user.role === "moderator"){
              hasModerator = true;
              modName = user.displayName;
              break;
            }
          }
          if(!hasModerator){
            apiRef.current.executeCommand('hangup');
            setShowWaitForModerator(true);
            startWaitingTimer(30);
          }
          else{
            setShowWaitForModerator(false);
            setRoleToolbar('moderator');
          }
        }
      });
    }
    else{
      setShowWaitForModerator(false);
    }
  };

  const updateTimer = (seconds) => {
    if(document.getElementById('waitForModTime')){
      document.getElementById('waitForModTime').textContent = seconds;
    }
  }
  const startWaitingTimer = (seconds) => {
    updateTimer(seconds);
    if (seconds > 0) {
      setTimeout(function() {
        startWaitingTimer(seconds - 1);
      }, 1000);
    } 
    else{
      location.reload();
    }
  }

  const handleHangupConfirm = async () => { console.log(leaveMeetingAction, "=======================");
    if(isModerator && leaveMeetingAction == "end"){
      await updateMeetingStatus();
      apiRef.current.executeCommand('endConference');
    }
    else{
      apiRef.current.executeCommand('hangup');
    }
    setIsHangupModalVisible(false);
    localStorage.clear(); 
    setTimeout(() => {
      window.location.replace(
        `${process.env.REACT_APP_PORTAL_ROOT}/dashboard`
      );
    }, 100);
  };

  const handleHangupCancel = () => {
    setIsHangupModalVisible(false);
  };

  const handleToolbarButtonClicked = (obj) => {
    if(obj.key === 'hangup' || obj.key === 'end-meeting'){
      let leaveMeetingAction = obj.key==="end-meeting"?"end":"leave";
      setLeaveMeetingAction(leaveMeetingAction);
      setIsHangupModalVisible(true);
    }
    return false;
  };

  const handleReadyToClose = () => {
    /* eslint-disable-next-line no-alert */
    localStorage.clear(); 
    setTimeout(() => {
      window.location.replace(
        `${process.env.REACT_APP_PORTAL_ROOT}/dashboard`
      );
    }, 100);


    /* if (isModerator) {
      localStorage.clear();
      await updateMeetingStatus();
      apiRef.current.executeCommand("endConference");
      setTimeout(() => {
        window.location.replace(
          `${process.env.REACT_APP_PORTAL_ROOT}/dashboard`
        );
      }, 100);
    } else {
      localStorage.clear(); 
      setTimeout(() => {
        window.location.replace(
          `${process.env.REACT_APP_PORTAL_ROOT}/dashboard`
        );
      }, 100);
    } */
  };

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: "sans-serif",
        textAlign: "center",
      }}
    >
      Loading..
    </div>
  );

  const invalidMeet = (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <span style={{ fontSize: "xxx-large" }}> {isLoading ? "Loading....." : "Invalid Meeting"} </span>
    </div>
  );
  const validMeet = (
    <JitsiMeeting
      appId = {process.env.REACT_APP_JITSIAPP_ID}
      jwt={jwtToken}
      domain={process.env.REACT_APP_JITSIDOMAIN}
      roomName={meetingId?.includes("M-") ? meetingId?.slice(2) : meetingId}
      spinner={renderSpinner}
      userInfo = {{
          displayName: userName,
          email: email
      }}
      /* interfaceConfigOverwrite={{
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: disableJoinLeaveNotifications, // Disable join/leave notifications
      }} */
      configOverwrite={{
        disableInviteFunctions: true, 
        disableSelfView: false,
        disable1On1Mode : true,
        defaultRemoteDisplayName: "XLI User",
        lobby: {
            autoKnock: true,
            enableChat: true
        },
        prejoinConfig: {
          // enabled: true,
          enabled: userName.length > 0 ? false : true,
        },
        /* toolbarConfig:{
          alwaysVisible:true
        }, */
        //notifications : [],
        disabledNotifications : disabledNotifications,
        disableTileEnlargement : true,
        deeplinking: {
          disabled: true,
        },

        faceLandmarks: {
          enableFaceCentering: true,
          enableFaceExpressionsDetection: true,
          enableDisplayFaceExpressions: true,
          enableRTCStats: true,
          faceCenteringThreshold: 20,
          captureInterval: 1000
        },


        startWithVideoMuted: true,
        startWithAudioMuted: startWithAudioMuted,
        disablePolls: disablePolls,
        subject: meetingName,
        hideConferenceSubject: false,
        toolbarButtons: isModerator
          ? moderatorToolbarButtons
          : participantToolbarButtons,
        buttonsWithNotifyClick: [
            {
              key: 'hangup',
              preventExecution: true
            },
            {
              key: 'end-meeting',
              preventExecution: true
            },
          ]
      }}
      interfaceConfigOverwrite={{
        HIDE_INVITE_MORE_HEADER: true,      // Remove "Login" button from the popup
      }}
      onApiReady={(externalApi) => handleApiReady(externalApi)}
      onReadyToClose={handleReadyToClose}
      getIFrameRef={handleJitsiIFrameRef1}
    />
  );

  const updateHyperbeamUrl = async (url, hbComputerInstance) => {
    let hbComputerSession = hbComputerInstance ?? hbComputer;
    if(hbComputerSession){
      if(!hbEmbed){
        await Hyperbeam(hyperbeamComputerDiv, hbComputerSession.embed_url, {
          adminToken: hbComputerSession.admin_token,
          videoPaused: false, 
          timeout: 30000
        }).then(function (hbEmbed) {
          setHbEmbed(hbEmbed);
          hbEmbed.tabs.update({ url: url })
          apiRef.current.executeCommand('startShareVideo', `${hbComputerSession.embed_url}/XLSharedApp_Iframe`);
          setHbSessionLoading(false);
        })
      }
      else{
        hbEmbed.tabs.update({ url: url })
        apiRef.current.executeCommand('startShareVideo', `${hbComputerSession.embed_url}/XLSharedApp_Iframe`);
        setHbSessionLoading(false);
      }
    }
  }

  const startHBCollaborate = async (url) => {
    if(hbComputer === null){
      setHbSessionLoading(true);
      await axios.get(`${process.env.REACT_APP_HB_API_ROOT}/computer`)
        .then(function (response) {
          if(response.data?.session_id){
            setHbComputer(response.data);
            (async () => {
              setIsCollaborate(true)
              await updateHyperbeamUrl(url, response.data);
            })();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else{
      (async () => {
        setIsCollaborate(true)
        await updateHyperbeamUrl(url, hbComputer);
      })();
    }
  }

  const stopHBCollaborate = async () => {
    await axios.post(`${process.env.REACT_APP_HB_API_ROOT}/end-session`, { sessionId : hbComputer.session_id})
      .then(function (response) {
        if(response.data?.session_id){
          console.log("HB session closed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    apiRef.current.executeCommand('stopShareVideo');
    setIsCollaborate(false)
    setHbComputer(null);
    setHbEmbed(null);
  }

  const startVideoContentSharing = async (url) => {
    apiRef.current.executeCommand('startShareVideo', `${url}`);
    setIsVideoContentShared(true);
  }
  const stopVideoContentSharing = async () => {
    apiRef.current.executeCommand('stopShareVideo');
    setIsVideoContentShared(false);
  }

  const handlConsentResponse = async () => {
    setShowRecordingConsent(false);
    setRecordingConsent(true);
    localStorage.setItem(`recording_consent_${meetingId}`, "true");
  }

  /* const flimStripZoom = () => {
    var newFsWidth = filmStripWidth==500?150:500;
    setFilmStripWidth(newFsWidth);
    apiRef.current.executeCommand('resizeFilmStrip', {
        width: newFsWidth // The desired filmstrip width
    });
  }; */

  
  /* const startRecording = () => {
    apiRef.current.executeCommand('startRecording', {
      mode: 'stream',
      rtmpStreamKey: `rtmp://recordings.xceptionallearningindia.com/live/${meetingId}.flv`,
    });
  }; */


  return (
    <>
      {showWaitForModerator && (<div className="waitForModerator">
          <div className="message">
            <h2>Please wait while the moderator joins...</h2>
            <h1>Retrying in <span id="waitForModTime">30</span> seconds</h1>
          </div>
        </div>)}
      <div id="hyperbeamComputerDiv"></div>
      {hbSessionLoading && <div className="loadingSpinnerWrapper"><LoadingOutlined className="loadingSpinner" /></div>}
      {isValidMeet && (meetingType != 'xl-meet') && moderatorFromApi && !showWaitForModerator? 
        <Sidebar 
        isCollaborate={isCollaborate} 
        stopHBCollaborate={stopHBCollaborate} 
        updateHyperbeamUrl={updateHyperbeamUrl}
        startHBCollaborate={startHBCollaborate} 
        isVideoContentShared={isVideoContentShared} 
        startVideoContentSharing={startVideoContentSharing}
        stopVideoContentSharing={stopVideoContentSharing}
        isOpen={isSidebarOpen} 
        toggleSidebar={toggleSidebar} 
        accessHeader={accessHeader}
        meetingId={meetingId.includes("M-") ? meetingId.slice(2) : meetingId}
        //localParticipantId={localParticipantId}
        //flimStripZoom={flimStripZoom}
        //startRecording={startRecording}
        setIsParticipantListOpen={setIsParticipantListOpen}
        toggleSideWindow={toggleSideWindow}
        /> 
        : null}
      {isValidMeet && !showWaitForModerator ? validMeet : invalidMeet}

      <Modal
        title={`Confirm ${leaveMeetingAction}-meeting`}
        open={isHangupModalVisible}
        onOk={handleHangupConfirm}
        onCancel={handleHangupCancel}
      >
        {(isModerator && leaveMeetingAction == "end") && <p>{"This action may end this meeting for all participants."}</p>}
        <p>{`Do you really want to ${leaveMeetingAction} this meeting?`}</p>
      </Modal>

      {isValidMeet && isParticipantListOpen && moderatorFromApi && isModerator && apiRef.current ? 
        <ParticipantList 
        api={apiRef.current} 
        participants={participants} 
        setIsParticipantListOpen={setIsParticipantListOpen}
        localParticipantId={localParticipantId}
        /> : null}

      {showRecordingConsent ? <DisclaimerModal handlConsentResponse={handlConsentResponse} /> : null}
      {/* <ApplauseOverlay 
        applaudType={localApplaudType} 
        showApplause={showApplause}
        setShowApplause={setShowApplause}
        />

      <SideWindow 
        url="https://xceptionallearningindia.com" 
        isOpen={isSideWindowOpen} 
        onClose={toggleSideWindow} 
      /> */}
    </>
  );
};

export default Room;
