import React, { useEffect, useRef  } from 'react';
import clapSound from '../assets/clap.mp3';
import clapGif from '../assets/clap.gif';
//import starsGif from './assets/stars.gif';
import './ApplauseOverlay.css';

const ApplauseOverlay = ({ applaudType, showApplause, setShowApplause }) => {
  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);

  useEffect(() => {
    const initAudioContext = async () => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const response = await fetch(clapSound);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      audioContextRef.current = audioContext;
      audioBufferRef.current = audioBuffer;
    };

    initAudioContext();
  }, []);

  const playAudio = () => {
    if (audioContextRef.current && audioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBufferRef.current;
      source.connect(audioContextRef.current.destination);
      source.start(0);
    }
  };

  useEffect(() => {
    if (showApplause) {
      playAudio(); // Play audio when applause is triggered
      const timer = setTimeout(() => setShowApplause(false), 10000); // Auto-hide overlay
      return () => clearTimeout(timer);
    }
  }, [showApplause, setShowApplause]);

  if (!showApplause) return null;

  // Select the corresponding GIF based on the applaud type
  const gifMap = {
    clap: clapGif,
    //stars: starsGif,
  };

  return (<>
    <div className="applause-overlay">
      <img src={gifMap[applaudType]} alt="Applause" className="applause-gif" />
    </div>
    </>
  );
};

export default ApplauseOverlay;
